import React from 'react';
import './App.css';
import DVDLogo from "./DVDLogo";
import useWindowDimensions from './WindowDimensions'

function App() {
  const { height, width } = useWindowDimensions();

  return (
    <div className="App">
      <svg width={width} height={height} style={{ backgroundColor: 'black' }}>
        <DVDLogo width={width} height={height} />
      </svg>
    </div>
  );
}

export default App;
